import { useQuery } from "react-query"
import { useGqlClient } from "../client"
import { useAuth } from "./useAuth"
import { allClusters } from "../queries/cluster"
import { Cluster } from "../../types/cluster"

export const useClustersQuery = (options?: any) => {
    const gqlClient = useGqlClient()
    const { token } = useAuth()
    return useQuery(
      'pharmacy_clusters', 
      async () => {
        const data = await gqlClient.request<{ pharmacy_clusters: Cluster[] }>(allClusters)
        return data?.pharmacy_clusters || []
      },
      { enabled: !!token, staleTime: Infinity, ...options },
    )
  }