import React, { FunctionComponent } from 'react'
import InputMessage, { InputMessageKind} from './InputMessage'

export type TextInputProps = {
  type: 'text' | 'password' | 'email' | 'tel'
  value: string
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  message?: string
  valid?: boolean
  name?: string
  id?: string
  bgColor?: string
  autocomplete?: string
}

const TextInput: FunctionComponent<TextInputProps> = ({
  disabled,
  message,
  name,
  id,
  valid,
  placeholder,
  value,
  bgColor,
  type = 'text',
  autocomplete,
  onChange,
  onBlur,
}) => {
  const disabledClass = disabled ? 'opacity-50 hover:shadow-none' : 'hover:shadow-focus'
  const handleOnchange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }
    onChange(event)
  }
  return (
    <>
      <div className={`mt-1 rounded-md w-full ${disabledClass}`}>
        <input
          placeholder={placeholder}
          autoComplete={autocomplete}
          disabled={disabled}
          value={value}
          name={name}
          id={id}
          onChange={handleOnchange}
          onBlur={onBlur}
          type={type}
          className={`form-input font-body text-gray-900 block w-full h-10 rounded-md px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5 ${
            bgColor ? bgColor : 'bg-gray-100'
          }`}
        />
      </div>
      {message ? <InputMessage kind={valid === false ? InputMessageKind.error : InputMessageKind.info}>{message}</InputMessage> : null}
    </>
  )
}

export default TextInput
