export enum ErrorKind {
    auth,
  }
  
  export class BaseError extends Error {
    readonly errorKind: ErrorKind
  
    constructor(errorKind: ErrorKind, message: string) {
      super(message)
      this.errorKind = errorKind
    }
  }
  