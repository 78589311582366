import React, { useEffect, useState } from 'react'
import Map from './Map'
import SimpleGoogleMap from './SimpleGoogleMap'
import ClustersList from './ClustersList'
import InfoWindowContainer from './InfoWindowContainer'
import { useClustersQuery } from '../graphql/hooks/clusters'
import { TopPanel } from './TopPanel'
import { Pharmacy } from '../types/pharmacy'
import { usePharmaciesQuery } from '../graphql/hooks/pharmacies'
/*
 * PROPS
 */
interface Props {
  setSelectedPharmacy: (id: string | undefined) => void
  selectedPharmacy: string | undefined
}
/*
 * COMPONENT
 */
const MapView: React.FC<Props> = ({
  setSelectedPharmacy,
  selectedPharmacy,
}) => {
  const { data: clusters } = useClustersQuery()
  const { data: pharmacies } = usePharmaciesQuery()

  const [pharmacyArray, setPharmacyArray] = useState<Pharmacy[]>([])
  const [showClusters, setShowClusters] = useState<string[]>([])

  useEffect(() => {
    const filteredClusters = pharmacies?.filter(cluster => showClusters.includes(cluster.id))
    const  pharmacy_array = filteredClusters
    ?.flatMap(cluster => cluster.pharmacies)
    .filter(pharmacy => pharmacy !== undefined) as Pharmacy[]
    setPharmacyArray(pharmacy_array)
  }, [pharmacies, showClusters])

  useEffect(() => {
    const clusterIds = clusters?.flatMap(item => item.id) || []
    setShowClusters(clusterIds)
  }, [clusters])
  
  const onCloseInfoWindow = () => {
    setSelectedPharmacy(undefined)
  }

  const changeColor = (id: string, color: string) => {
    const preparedPharmacies = pharmacyArray.map((pharmacy) => {
      if (pharmacy.id !== id) {
        return pharmacy
      } else {
        return {
          ...pharmacy,
          colorcode: color
        }
      }
    })
    setPharmacyArray(preparedPharmacies)
  }

    return (
      <SimpleGoogleMap>
        <Map 
          pharmacies={pharmacyArray} 
          onClick={setSelectedPharmacy} 
        >
          <InfoWindowContainer
              onClose={onCloseInfoWindow}
              selectedPharmacy={selectedPharmacy}
              pharmacies={pharmacyArray}
              changeColor={changeColor}
            />
          <div className="fixed mt-0 mx-2 p-3 mt-2 right-0 w-1/6 bg-white rounded-md">
            <TopPanel />
          </div>
          <div className="fixed bottom-0 m-2 mr-20 p-2 pr-12 left-0 w-auto overflow-x-auto h-144px flex justify-start bg-white rounded-md">
            <ClustersList 
              showClusters={showClusters}
              setShowClusters={setShowClusters}
            />
          </div>
        </Map>
      </SimpleGoogleMap>
    )
}

export default MapView
