/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { createContext, useCallback, useContext, useState, ReactNode } from 'react'

export interface IMap {
  fitBounds: (arg0: any) => void
}

export interface ILocation {
    longitude: number;
    latitude: number;
}


/**
 * Context for a Google Map
 *
 * Contains the map instance, and helper methods
 */
export interface IMapContext {
  mapInstance: IMap | null
  zoomToLocation: (location: ILocation) => void
  fitToMarkers: (markers: Pick<any, 'id' | 'address'>[]) => void
  initializeMap: (map: IMap) => void
}

/*
 * CONTEXT
 */
export const MapContext = createContext<IMapContext | null>(null)
MapContext.displayName = 'MapContext'

/*
 * PROVIDER
 */
const MapProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [map, setMap] = useState<IMap | null>(null)

  const zoomToLocation = useCallback(
    (location: ILocation) => {
      if (!map) {
        // eslint-disable-next-line no-console
        console.warn('Cannot zoom, map is not initialized yet')
        return
      }
      const { longitude, latitude } = location
      const bounds = new (window as any).google.maps.LatLngBounds()
      const pharmacyLngLatPoint = new (window as any).google.maps.LatLng(latitude, longitude)
      bounds.extend(pharmacyLngLatPoint)
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new (window as any).google.maps.LatLng(
          (bounds.getNorthEast().lat() as number) + 0.01,
          (bounds.getNorthEast().lng() as number) + 0.01,
        )
        const extendPoint2 = new (window as any).google.maps.LatLng(
          (bounds.getNorthEast().lat() as number) - 0.01,
          (bounds.getNorthEast().lng() as number) - 0.01,
        )
        bounds.extend(extendPoint1)
        bounds.extend(extendPoint2)
      }
      map.fitBounds(bounds)
    },
    [map],
  )

  const fitToMarkers = useCallback(
    (markers: Pick<any, 'id' | 'address'>[]) => {
      if (!map) {
        // eslint-disable-next-line no-console
        console.warn('Cannot fit map to markers, map is not initialized yet')
        return
      }
      markers.forEach((pharmacy, index) => {
        if (map) {
          const bounds = new (window as any).google.maps.LatLngBounds()

          //gradually extends the bounds to include the pharmacies
          //https://groups.google.com/g/google-maps-js-api-v3/c/HyAFbvzoGGo?pli=1
          const pharmacyLongitude = pharmacy.address.longitude
          const pharmacyLatitude = pharmacy.address.latitude
          const pharmacyLngLatPoint = new (window as any).google.maps.LatLng(pharmacyLatitude, pharmacyLongitude)

          bounds.extend(pharmacyLngLatPoint)

          if (index === markers.length - 1 && map) {
            //we have reached the end of the pharmacies markers
            //fit bounds to the map
            //https://stackoverflow.com/questions/3334729/google-maps-v3-fitbounds-zoom-too-close-for-single-marker
            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
              const extendPoint1 = new (window as any).google.maps.LatLng(
                (bounds.getNorthEast().lat() as number) + 1.3,
                (bounds.getNorthEast().lng() as number) + 1.3,
              )
              const extendPoint2 = new (window as any).google.maps.LatLng(
                (bounds.getNorthEast().lat() as number) - 1.3,
                (bounds.getNorthEast().lng() as number) - 1.3,
              )
              bounds.extend(extendPoint1)
              bounds.extend(extendPoint2)
            }
            map.fitBounds(bounds)
          }
        }
      })
    },
    [map],
  )

  return (
    <MapContext.Provider
      value={{
        zoomToLocation,
        fitToMarkers,
        mapInstance: map,
        initializeMap: setMap,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

/*
 * HOOK
 */
export const useMap = () => {
  const context = useContext(MapContext)

  if (!context) {
    throw new Error("Provider not found: Attemting to use the crmContext, without it's provider")
  }
  return context
}

export default MapProvider
