import { gql } from "graphql-request"

export const allClusters = gql`
  query{
    pharmacy_clusters {
      id
      name
      pharmacy_clusterscode
      colorcode
      pharmacy_count
      agent {
        name
      }
    }
  }
`
