import { useContext } from 'react'
import { AuthContext } from '../../contexts/authContext'
import { IAuthContext } from '../../contexts/authContext';

/**
 * Wraps the AuthContext for interacting with the Authentication Layer
 */
export const useAuth = () => {
    const authContext = useContext(AuthContext);
  
    if (!authContext) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
  
    return authContext as IAuthContext;
  };