import React, { useState } from 'react';
import './App.css';
import MapView from './components/MapView';
import MapProvider from './contexts/mapContext';
import { useLoadScript } from '@react-google-maps/api'
import { googleMapsApiKey } from './constants';
import Spinner from './components/loader.gif'
import { AuthProvider } from './contexts/authContext';
import LoginPage from './pages/Login';
import { Route, Routes, Navigate } from 'react-router-dom';
import { LOGIN_ROUTE, OVERVIEW_ROUTE } from './routes';
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { secondsToMilliseconds } from 'date-fns'

function App() {
  const [selectedPharmacyId, setSelectedPharmacyId] = useState<string | undefined>()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: secondsToMilliseconds(60), // The minimum time before data is refetched
        notifyOnChangeProps: 'tracked',
      },
    },
  })

  if (!isLoaded) {
    return <SpinnerComponent />
  }
  return (
    <div className="flex w-full bg-transparent z-40  h-full">
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <MapProvider>
            <Router>
            <Routes>
              <Route element={<Navigate to="/login" />} path="/" />
              <Route
                path={LOGIN_ROUTE}
                element={
                    <React.Suspense fallback={<SpinnerComponent />}>
                      <LoginPage />
                    </React.Suspense>
                }
              />
              <Route
                path={OVERVIEW_ROUTE}
                element={
                    <React.Suspense fallback={<SpinnerComponent />}>
                      <MapView
                        setSelectedPharmacy={setSelectedPharmacyId} 
                        selectedPharmacy={selectedPharmacyId}
                      />
                    </React.Suspense>
                }
              />
            </Routes>
            </Router>
          </MapProvider>
        </QueryClientProvider>
      </AuthProvider>
    </div>
  );
}

const SpinnerComponent = () => (
  <div className="h-screen w-screen bg-white z-20 absolute flex items-center justify-center">
    <div className="flex items-center justify-center flex-wrap max-w-48">
      <img className="flex w-24" src={Spinner} alt="" />
      <br />
      <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">{'Daten werden geladen.'}</div>
    </div>
  </div>
)

export default App;
