/* eslint-disable react/jsx-no-literals */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { InfoWindowF } from '@react-google-maps/api'
import React from 'react'
import { useClustersQuery } from '../graphql/hooks/clusters'
import { useChangeClusterId } from '../graphql/hooks/pharmacies'
import { Pharmacy } from '../types/pharmacy'

interface Props {
  selectedPharmacy: string | undefined
  onClose: () => void
  pharmacies: Pharmacy[],
  changeColor: (id: string, color: string) => void
}

const InfoWindowContainer = ({
  selectedPharmacy,
  onClose,
  pharmacies,
  changeColor
}: Props) => {

  const { data: clusters } = useClustersQuery()
  const { mutate: changePharmacyClusterId } = useChangeClusterId()

  const pharmacy = pharmacies?.find(pharmacy => pharmacy?.id === selectedPharmacy)

  const lat = pharmacy?.address?.latitude ?? 0
  const lng = pharmacy?.address?.longitude ?? 0
  const pixelOffset = new window.google.maps.Size(0, -50)

  const clusterAgentName = (name: String) => {
    return name ? name.split(' ')[1] : ''
  }

  return (
    <React.Fragment>
      {pharmacy && (
        <InfoWindowF
          position={{
            lng: lng,
            lat: lat,
          }}
          options={{ pixelOffset: pixelOffset }}
          onCloseClick={onClose}
        > 
          <div>
            <div className="flex mb-3">
              <div>
                <p>{`ID: ${pharmacy?.id}`}</p>
                <p className="font-medium mb-0.5 mt-3">{pharmacy?.name}</p>
                <p>{pharmacy?.address?.address_name}</p>
                <p className="mb-3">{`${pharmacy?.address?.zipcode || ''} ${pharmacy?.address?.city || ''}`}</p>
                <a className="font-medium mb-0.5" href={`tel:${pharmacy?.phone}`}>
                  {`Tel. ${pharmacy?.phone || '_'}`}
                </a>
                <p>{`Fax.${pharmacy?.fax}`}</p>
                <br />
              </div>
            </div>
            <div className='flex flex-col max-h-250px gap-1 flex-wrap'>
              {clusters?.map((cluster) => (
                <div 
                  key={cluster.id} 
                  className='flex flex-row gap-1'
                  onClick={async () => {
                    await changePharmacyClusterId({
                      id: pharmacy.id,
                      pharmacy_cluster_id: cluster.id
                    })
                    changeColor(pharmacy.id, cluster.colorcode)
                  }}
                >
                  <div className='h-20px w-56px text-xxs flex justify-center items-center' style={{ backgroundColor: `#${cluster.colorcode}` }}>{cluster.agent?.name ? clusterAgentName(cluster.agent?.name) : '–'}</div>
                  <div className="text-xxs flex items-center">{`${cluster.pharmacy_clusterscode}–${cluster.name}`}</div>
                </div>
              ))}
            </div>
          </div>
        </InfoWindowF>
      )}
    </React.Fragment>
  )
}

export default InfoWindowContainer
