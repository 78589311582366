import SignOutButton from "./SignOutButton"
import logo from '../components/darklogo.svg'


export const TopPanel: React.FC = () => {
  return (
		<div className="flex flex-row align-items-center justify-between">
			<div>
				<img src={logo} alt="Logo" className="h-16 w-auto"/>
			</div>
			<SignOutButton />
		</div>
  )
}