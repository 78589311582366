import React, { PropsWithChildren, useMemo } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import MapMarkers from './MapMarkers'
import { determineMapIcon } from '../utils/utils'
import { useMap } from '../contexts/mapContext'
import { Pharmacy } from '../types/pharmacy'

const Map = ({ children, pharmacies, onClick }: PropsWithChildren<{ pharmacies: Pharmacy[] | undefined; onClick?: (id: string) => void; }>) => {
  const { fitToMarkers, mapInstance } = useMap()

  const pharmacyAddresses = useMemo(() => (pharmacies?.map ? pharmacies.map(({ id, address }) => ({ id, address })) : []), [pharmacies])
  const markers = useMemo(
    () =>
      pharmacies?.map((pharmacy) => {

        return {
          id: pharmacy.id,
          lat: pharmacy.address?.latitude,
          long: pharmacy.address?.longitude,
          icon: determineMapIcon({
            pharmacy,
          }),
          text: pharmacy.name,
          onClick,
        }
      }) ?? [],

    [pharmacies, onClick],
  )

  useDeepCompareEffect(() => {
    if (mapInstance) {
      fitToMarkers(pharmacyAddresses)
    }
  }, [pharmacyAddresses, mapInstance])
  return (
    <React.Fragment>
      <MapMarkers markers={markers} />
      {children}
    </React.Fragment>
  )
}

export default Map
