import { gql } from 'graphql-request'

export const changeClusterId = gql`
  mutation changeClusterId($id: ID!, $pharmacy_cluster_id: String!) {
    changeClusterId(id: $id, pharmacy_cluster_id: $pharmacy_cluster_id) {
      id
      name
      pharmacy_cluster_id
    }
  }
`
