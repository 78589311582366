//import { GraphQLError } from 'graphql-request/dist/types'
import { GraphQLError } from "graphql"

/**
 * Defines a response which is returned by graphql-request
 */
export interface RawResponse<ModelType> {
  data?: ModelType
  // tslint:disable-next-line:no-any
  extensions?: any
  headers?: Headers
  status?: number
  errors?: GraphQLError[]
}

interface IResponse<ModelType> {
  data?: ModelType
  errors?: GraphQLError[]
  status?: number
  headers?: Headers
  progress: ResponseProgress
}

export enum ResponseProgress {
  loading,
  done,
}

/**
 * A Response represents data that is returned by the API.
 */
export class Response<ModelType> implements IResponse<ModelType> {
  /**
   * The requested model
   */
  readonly data?: ModelType

  /**
   * Errors in case any occoured
   */
  readonly errors?: GraphQLError[]

  /**
   * The HTTP status code
   */
  readonly status?: number

  /**
   * Indicates wether the request is in loading or done
   */
  readonly progress: ResponseProgress

  /**
   * Headers aggregated by the HTTP response
   */
  readonly headers?: Headers

  constructor({ data, errors, status, headers }: RawResponse<ModelType>, progress = ResponseProgress.loading) {
    this.data = data
    this.errors = errors
    this.status = status
    this.headers = headers
    this.progress = progress
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  static emptyLoadingResponse<ModelType>(): Response<ModelType> {
    return new Response<ModelType>({}, ResponseProgress.loading)
  }
}
