import { useAuth } from '../graphql/hooks/useAuth'
import Button, { ButtonKind } from '../components/Button'
import InputLabel from '../components/InputLabel'
import InputMessage, { InputMessageKind } from '../components/InputMessage'
import TextInput from '../components/TextInput'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from "react-router-dom"
import * as Yup from 'yup'
import logo from '../components/darklogo.svg'
import { OVERVIEW_ROUTE } from '../routes'

type LoginFormValues = {
  email: string
  password: string
}

const LoginValidationScheme = Yup.object().shape({
  email: Yup.string().email('Ungültige E-Mail').required('Pflichtfeld'),
  password: Yup.string().min(3, 'Zu kurz').required('Pflichtfeld'),
})

const initialFormValues: LoginFormValues = {
  email: '',
  password: '',
}

const LoginPage: React.FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const onFormSubmit = async (values: LoginFormValues) => {
    const success: boolean = await auth.signIn(values.email, values.password)
    if (success) {
        navigate(OVERVIEW_ROUTE)
    console.log('success')
    } 
  }

  const { errors, touched, handleSubmit, handleChange, isSubmitting, values } = useFormik({
    initialValues: initialFormValues,
    onSubmit: onFormSubmit,
    validationSchema: LoginValidationScheme,
  })

  return (
    <div className="w-full h-screen flex flex-wrap overflow-hidden bg-orange-100">
      <div className="bg-white h-64 w-110 transform -rotate-3 -mt-32 px-64 -ml-3" />
      <div className="absolute ml-16 mt-16">
        <img className="h-16 w-auto" src={logo} alt="aposphäre CRM" />
      </div>
      <div className="flex absolute w-full h-full bg-no-repeat bg-center justify-center items-center">
        <img className="absolute -ml-56 mt-20 animate-spin-slow" src="/login-rotate-1.svg" alt="" />
        <img className="absolute -mr-450 -mt-350 animate-wiggle" src="/login-rotate-2.svg" alt="" />
        <img className="absolute" src="/bg-login.svg" alt="" />
        <form
          onSubmit={handleSubmit}
          method="POST"
          autoComplete="on"
          className="flex flex-wrap z-50 w-450px bg-white shadow-2xl rounded-lg h-fit justify-center pt-12 pb-12"
        >
          <h1 className="text-blue-700 text-xl font-medium text-center w-full mb-8">{'Bitte loggen Sie sich ein:'}</h1>
          <div className="w-2/3">
            <InputLabel htmlFor="email">{'Email'}</InputLabel>
            <TextInput id="email" name="email" autocomplete="email" type="text" value={values.email} onChange={handleChange} />
            {errors.email && touched.email ? <InputMessage kind={InputMessageKind.error}>{errors.email}</InputMessage> : null}
          </div>
          <div className="w-2/3 mt-8">
            <InputLabel htmlFor="password">{'Passwort'}</InputLabel>
            <TextInput type="password" id="password" name="password" autocomplete="password" value={values.password} onChange={handleChange} />
            {errors.password && touched.password ? <InputMessage kind={InputMessageKind.error}>{errors.password}</InputMessage> : null}
          </div>
          <div className="flex w-full mt-8 justify-center">
            <Button type="submit" kind={ButtonKind.primary} disabled={isSubmitting ? true : false}>
              {'Anmelden'}
            </Button>
          </div>
        </form>
      </div>
      <div className="flex h-64 transform -rotate-2 rounded-xl bg-blue-700 text-white fixed w-110 bottom-0 -mb-40 -ml-4 text-sm font-body py-4 px-8 justify-between" />
      <footer className="flex text-blue-300 fixed w-full bottom-0 text-sm font-body py-6 px-8">
        <div className="w-full flex justify-end">
          <div className="flex pr-6 border-r border-blue-300 ">{'aposphäre'}</div>
          <a className="ml-6 mr-6 hover:text-white hover:underline" href="https://www.aposphaere.de/impressum/" target="_blank" rel="noopener noreferrer">
            {'Impressum'}
          </a>
          <a className="mr-6 hover:text-white hover:underline" href="https://www.aposphaere.de/datenschutz/" target="_blank" rel="noopener noreferrer">
            {'Datenschutz'}
          </a>
          <a className="hover:text-white hover:underline" href="https://www.aposphaere.de/#" target="_blank" rel="noopener noreferrer">
            {'AGB'}
          </a>
        </div>
      </footer>
    </div>
  )
}

export default LoginPage
