import { gql } from "graphql-request"

export const pharmacies = gql`
  query{
    pharmacy_clusters {
      id
      pharmacies {
        id
        name
        phone
        fax
        colorcode
        address {
          address_name
            zipcode
          city
          latitude
          longitude
        }
      }
    }
  }
`

export const pharmaciesByClusters = gql`
  query($clusterIds: [String]!) {
    pharmacies_by_cluster_ids(pharmacy_cluster_ids: $clusterIds) {
      id
      name
      pharmacy_cluster_id
      colorcode
      address {
        address_name
          zipcode
        city
        latitude
        longitude
      }
    }
  }
`