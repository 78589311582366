/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Marker } from '@react-google-maps/api'
import React from 'react'
declare let google: any
export type MapMarkerProps = {
  long?: number
  lat?: number
  text?: string
  onClick?: (id: string) => void
  id: string
  icon?: string
}

export const MapMarker: React.FunctionComponent<MapMarkerProps> = ({ lat, long, onClick, id, icon }) => {
  const iconProp = { url: icon || '', scaledSize: new google.maps.Size(23, 35) }
  return <Marker position={{ lat: lat || 0, lng: long || 0 }} icon={iconProp} onClick={onClick ? () => onClick(id) : () => null} />
}
