import React, { memo } from 'react'
import { useClustersQuery } from '../graphql/hooks/clusters'
import { Cluster } from '../types/cluster'

type Props = {
  showClusters: string[],
  setShowClusters: (clusters: string[]) => void,
}

const ClustersList: React.FC<Props> = ({ showClusters, setShowClusters }) => {
  const { data: clusters} = useClustersQuery()

  const tagStyle =  "flex flex-row gap-1 border-gray-400 border border-solid rounded-md outline-none p-1 h-40px"

  const handleClick = (id: string) => {
    const index = showClusters.indexOf(id);
    
    if (index === -1) {
      setShowClusters([...showClusters, id]);
    } else {
      const updatedClusters = [...showClusters];
      updatedClusters.splice(index, 1);
      setShowClusters(updatedClusters);
    }
  }

  return (
    <div className='flex flex-col gap-1 flex-wrap'>
      {clusters?.map((cluster: Cluster) => (
        <div
          key={cluster.id}
          className={`${tagStyle}`}
          onClick={() => handleClick(cluster.id)}
          style={{ 
            borderColor: showClusters.includes(cluster.id) ? `#${cluster.colorcode}` : '#D6DBE0',
            borderWidth: showClusters.includes(cluster.id) ? '2px' : '1px'
          }}
        >
          <div className='h-30px w-42px rounded-md text-sm flex justify-center items-center' style={{ backgroundColor: `#${cluster.colorcode}` }}>{cluster.pharmacy_count}</div>
          <div className='flex flex-col'>
            <div className="text-xxs">{cluster.pharmacy_clusterscode}</div>
            <div className="text-xxs">{cluster.name}</div>
          </div>
        </div>
      ))}
    </div>
  )
}
export default memo(ClustersList)
