/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

import customMarker from '../components/customMarket'
import { Pharmacy } from '../types/pharmacy'

const getIcon = (
  pharmacy: Pharmacy,
) => {

  const result = { color: `#${pharmacy.colorcode}` };
  return result;
};


export const determineMapIcon = ({
  pharmacy,
}: {
  pharmacy: Pharmacy
}) => {
  const iconData = getIcon(pharmacy)
  return customMarker({ ...iconData })
}
